import React from 'react'
import Promise from 'bluebird'

import { browserHistory } from "browser/history"
import { Position, Toast } from 'browser/components/atomic-elements/atoms/toast/toast'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

export const enum NavigationPreference {
  KEEP = 'keep',
  REMOVE = 'remove',
}

export const navigateTo = (entityId: string = undefined, viewId: string = NavigationPreference.KEEP, args: any) => {
  if (!entityId && !viewId) {
    return
  }

  let viewPath = ''
  if (viewId === NavigationPreference.REMOVE) {
    // no-op, default value
  } else if (viewId === NavigationPreference.KEEP) {
    const urlSegments = window.location.pathname.split('/').splice(1)

    // this is a hack
    if (urlSegments[2] === 'view') {
      viewPath = `/${urlSegments[0]}/${urlSegments[1]}/${urlSegments[2]}/${urlSegments[3]}`
    } else if (urlSegments[0] === 'view') {
      viewPath = `/${urlSegments[0]}/${urlSegments[1]}`
    }
  } else {
    viewPath = `/view/${viewId}`
  }

  let entityPath = ''
  if (entityId !== undefined) {
    entityPath = `/entity/${entityId}`
  }

  const destinationPath = `${viewPath}${entityPath}`

  return browserHistory.push({
    pathname: destinationPath
  })
}

export const showToast = (message, delay=3000) => {
  const toast = Toast.show({
    message: (
      <div className="c-toastContents">
        <Icon
          className="c-toastContents-icon mr3"
          icon={IconNames.INFO_SIGN}
          size={30}

        />
        <div className="c-toastContents-message">
          {message}
        </div>
      </div>
    ),
    position: Position.BOTTOM_RIGHT,
    isLightMode: true,
  })

  return new Promise((resolve) => {
    setTimeout(() => {
      Toast.dismiss(toast)
      resolve()
    }, delay)
  })
}
